var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "tab" }, [
      _c(
        "ul",
        { class: { "fitable-tab": _vm.fitable } },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "li",
            {
              key: tab.id,
              class: tab.isActive ? "on" : tab.isDisabled ? "disabled" : "",
            },
            [
              _vm.pageType === "containerPop"
                ? _c(
                    "a",
                    {
                      attrs: { href: tab.href },
                      on: {
                        click: function ($event) {
                          return _vm.selectTab(tab)
                        },
                      },
                    },
                    [_vm._v(_vm._s(tab.title))]
                  )
                : _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.pageMove(tab)
                        },
                      },
                    },
                    [_vm._v(_vm._s(tab.title))]
                  ),
            ]
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "tabs-details" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div v-show="isActive"><slot></slot></div>
</template>

<script>
export default {
  props: {
    name: {
      required: true
    },
    title: {
      required: true
    },
    path: {
      required: false
    },
    selected: {
      default: false
    },
    disabled: {
      default: false
    }
  },
  data () {
    return {
      isActive: false,
      isDisabled: false
    }
  },
  computed: {
    href () {
      let returnPath = ''
      if (this.$ekmtcCommon.isNotEmpty(this.path)) {
        returnPath = '#' + this.path + '/' + this.name.toLowerCase().replace(/ /g, '-')
      } else {
        returnPath = '#' + this.$route.path
      }

      return returnPath
    }
  },
  mounted () {
    this.isActive = this.selected
    this.isDisabled = this.disabled
  },
  watch: {
    isActive: function () {
    },
    isDisabled: function () {}
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="tab">
      <ul :class="{'fitable-tab': fitable}">
        <li v-for="tab in tabs" :key="tab.id" :class="tab.isActive ? 'on' : (tab.isDisabled ? 'disabled' : '')">
          <a v-if="pageType === 'containerPop'" :href="tab.href" @click="selectTab(tab)">{{ tab.title }}</a>
          <a v-else @click.prevent="pageMove(tab)">{{ tab.title }}</a>
        </li>
      </ul>
    </div>

    <div class="tabs-details"><slot></slot></div>
  </div>
</template>

<script>
import transCommon from '@/api/rest/trans/transCommon'
import trans from '@/api/rest/trans/trans'
export default {
  name: 'ETab',
  props: {
    pageType: {
      type: String,
      default: ''
    },
    fitable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabs: []
    }
  },

  mounted () {
    this.tabs = this.$children
  },
  methods: {
    selectTab (selectedTab) {
      this.$emit('tabName', selectedTab.name)
      if (selectedTab.name !== undefined) {
        this.tabs.forEach(tab => {
          tab.isActive = (tab.name === selectedTab.name)
          tab.isDisabled = tab.disabled
        })
      }
    },
    async pageMove (tab) {
      if (this.pageType === 'DETAIL') {
        let result = this.$parent.compareCheck()
        if (this.$parent.memberDetail.userCtrCd === 'JP' && (this.$route.name === 'bl' || this.$route.name === 'bl-certificate' || this.$route.name === 'free-time')) {
          let actYn = ''
          await trans.findOnlyActYn(this.$parent.detailBlNo).then(res => {
            actYn = res.data.onlyActYn
          })
          if (actYn === 'Y' && (tab.name === 'booking-edit' || tab.name === 'freight')) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.038')) //권한이 없습니다.
            return
          }
        }
        if (!result) {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('js.common.009'), useConfirmBtn: true })) {
            return
          }
        }

        if (this.$route.name === 'booking-edit') {
          const blNo = this.$parent.detailBlNo
          const srNo = this.$parent.detailSrRno
          //console.log(`>>>>> S/R NO, B/L NO : ${this.$parent.detailSrRno}, ${this.$parent.detailBlNo}`)
          if (this.$ekmtcCommon.isNotEmpty(blNo) || this.$ekmtcCommon.isNotEmpty(srNo)) {
            let auth = ''
            const authParams = {
              bizCd: (this.$ekmtcCommon.isNotEmpty(blNo) ? 'BL' : 'SR'),
              rqRno: (this.$ekmtcCommon.isNotEmpty(blNo) ? blNo : srNo),
              eiCatCd: ''
            }

            await transCommon.isHaveAuth(authParams).then(async res => {
              auth = res.data
            })
            if (auth !== 'Y') {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK232') })
              return
            }
          }
        }

        if (tab.name === 'bl' && this.$parent.blPopYn !== 'Y') {
          if (this.$ekmtcCommon.isNotEmpty(this.$parent.detailBlNo)) {
            this.$parent.fnNoticePop()
            return
          }
        }
      }

      this.$router.push(tab.path + '/' + tab.name).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  .tab a {cursor: pointer; user-select: none;}
  .fitable-tab {
    display: flex;

    & > li {
      width: 100% !important;
    }
  }
</style>
